import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer style={footerStyle}>
      <div style={contentStyle}>
        <p>
          <p>
            <Link
              to="/aboutus"
              style={{
                marginBottom: 20,
                color: "black",
                textDecoration: "none",
              }}
            >
              About Us
            </Link>
          </p>
          <p>
            <Link
              to="/values"
              style={{
                marginBottom: 20,
                color: "black",
                textDecoration: "none",
              }}
            >
              Our Values
            </Link>
          </p>
        </p>
      </div>
      <div style={contentStyleTwo}>
        <a
          href="mailto:dr.dbrown@icloud.com"
          style={{ color: "black", textDecoration: "none" }}
        >
          Contact Us
        </a>

        <p>ABN: 15 776 628 622 &copy; 2024</p>
      </div>
    </footer>
  );
}

const footerStyle = {
  display: "flex",
  background: "#D3D3D3",
  textAlign: "center",
  alignItems: "center",
  bottom: "0",
  width: "100%",
  borderTop: "1px solid #e7e7e7",
  position: "relative",
  left: "0",
  right: "0",
  height: "100px",
  flexDirection: "row",
  justifyContent: "space-between",
};

const contentStyle = {
  display: "flex",
  flexDirection: "column",
  marginLeft: 20,
  fontFamily: "Verdana, sans-serif",
  fontSize: "14px",
  alignItems: "flex-start",
  color: "#000000",
  fontWeight: "bold",
};

const contentStyleTwo = {
  display: "flex",
  flexDirection: "column",
  marginRight: 20,
  fontFamily: "Verdana, sans-serif",
  fontSize: "14px",
  alignItems: "flex-end",
  color: "#000000",
  fontWeight: "bold",
};

export default Footer;
