import React from "react";
import "./AboutUs.css";
import DocDB from "./Img/DBprofilephoto.jpg";
import swift from "./Img/swift.jpeg";
import react from "./Img/react-native.jpeg";
import python from "./Img/python.jpg";
import rust from "./Img/Rust.png";
import docker from "./Img/docker.png";
import kubernetes from "./Img/kubernetes.jpeg";

function AboutUs() {
  return (
    <div className="about-container">
      <h1 className="glowing-heading">About Us</h1>
      <div className="grid-container">
        <div className="card">
          <div>
            <img src={DocDB} alt="Profile" />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 20,
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <img src={swift} alt="Profile" style={{ width: "20%" }} />
              <img src={react} alt="Profile" style={{ width: "20%" }} />
              <img src={python} alt="Profile" style={{ width: "20%" }} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 20,
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <img src={rust} alt="Profile" style={{ width: "20%" }} />
              <img src={docker} alt="Profile" style={{ width: "20%" }} />
              <img src={kubernetes} alt="Profile" style={{ width: "20%" }} />
            </div>
          </div>
        </div>
        <div className="card">
          <h2 style={{ fontFamily: "'Days One', sans-serif" }}>Our Company</h2>
          <p>
            Nox Ignis Software is is a creative software enginering company,
            based in Castle Hill NSW, that specializes in creating custom
            software solutions for businesses of all sizes. We are passionate
            about using technology to solve real-world problems and help our
            clients succeed.
          </p>

          <h2 style={{ fontFamily: "'Days One', sans-serif", marginTop: 50 }}>
            Meet Our Founder
          </h2>
          <p>
            At the heart of Nox Ignis is our founder Doc Brown, a creative and
            talented software engineer with a passion for building a better
            world.
          </p>
          <p>
            After retiring from Medicine, with a career helping a diverse range
            of people in Emergency Medicine and Family Medcine. Doc Brown
            continues with the craft of melding scientific evidence and artistry
            to enhance value through this software company.
          </p>
          <p>
            With a keen eye for detail and a commitment to excellence, he is
            building Nox Ignis Software into a powerhouse of technical expertise
            and creativity.
          </p>
          <p>
            Our founder’s unique blend of technical skill and artistic vision
            drives the company’s success. Whether it's crafting elegant code or
            designing user-friendly interfaces, our work is a testament to the
            power of combining creativity with cutting-edge technology.
          </p>
          <p>
            With a deep understanding of both art and science, Doc Brown leads
            Nox Ignis Software in delivering solutions that are not only
            functional but also consider user-experience based design.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
