import React from "react";
import "./Products.css";
import memoryDiariesImg from "./Img/3generations.webp";
import bossEntrepreneurImg from "./Img/BossBrains.png";
import RedK from "./Img/tmp9_f15gle.jpg";
import NoxSentinel from "./Img/noxsentinel.jpg";
import CerberusImg from "./Img/Cerberus.png";
import HuskieImg from "./Img/Huskie.png";

function Products() {
  return (
    <div className="products-container">
      <h1 className="glowing-heading">Software Products</h1>
      <div className="products-list">
        <div className="product-item">
          <img
            src={memoryDiariesImg}
            alt="Memory Diaries"
            className="product-image"
          />
          <div className="product-description">
            <h2 style={{ fontFamily: "'Days One', sans-serif" }}>
              Memory Diaries
            </h2>
            <p>
              Memory Diaries is a web and mobile application designed for the
              elderly to record and share their life events and memories.
              Preserve your stories for future generations. Advanced
              cybersecurity and privacy policies are strictly enforced. Now
              availiable.
            </p>
            <p>
              <a href="http://memorydiaries.com">www.memorydiaries.com</a>
            </p>
          </div>
        </div>

        <div className="product-item">
          <img src={CerberusImg} alt="Nox Cerberus" className="product-image" />
          <div className="product-description">
            <h2 style={{ fontFamily: "'Days One', sans-serif" }}>
              Nox Cerberus
            </h2>
            <p>
              Nox Cerberus is an advanced OAuth, OICD, FIDO2 and WebAuthN
              authentication controller API. It combines the industry leading
              authentication practices of OAuth and OICD with the latest in
              FIDO2 and WebAuthN cybersecurity practices. Nox Cerberus is
              designed to be a secure, reliable and easy to use gateway
              controller for web and mobile applications. Production nearing
              completion...
            </p>
            <p>
              <a href="http://noxcerberus.com">www.noxcerberus.com</a>
            </p>
          </div>
        </div>

        <div className="product-item">
          <img
            src={HuskieImg}
            alt="Huskie Crypto Exchange"
            className="product-image"
          />
          <div className="product-description">
            <h2 style={{ fontFamily: "'Days One', sans-serif" }}>
              Huskie Crypto Exchange
            </h2>
            <p>
              Huskie Crypto Exchange is a secure, reliable and easy to use
              cryptocurrency exchange platform. Huskie Crypto Exchange is
              designed to be a secure, reliable and for web and mobile
              applications. It combines the Nox Cerberus authentication
              controller with custom trading engine, wallet services, web socket
              API, market matching engine and blockchain daemons. Production in
              progress...
            </p>
            <p>
              <a href="http://huskifi.com">www.huskifi.com</a>
            </p>
          </div>
        </div>

        <div className="product-item">
          <img
            src={bossEntrepreneurImg}
            alt="Boss Entrepreneur"
            className="product-image"
          />
          <div className="product-description">
            <h2 style={{ fontFamily: "'Days One', sans-serif" }}>
              Boss Entrepreneur
            </h2>
            <p>
              Boss Entrepreneur is a web application designed for business
              creators to plant the seeds of their business and nurture it as it
              grows. Coming soon to web and mobile platforms.
            </p>
            <p>
              <a href="http://bossentrepreneur.com.au">
                www.bossentrepreneur.com.au
              </a>
            </p>
          </div>
        </div>

        <div className="product-item">
          <img
            src={NoxSentinel}
            alt="Boss Entrepreneur"
            className="product-image"
          />
          <div className="product-description">
            <h2 style={{ fontFamily: "'Days One', sans-serif" }}>
              Nox Sentinel
            </h2>
            <p>
              Nox Sentinel is a cybersecurity vulnerability scanner designed to
              scan application-program-interfaces, cloud and web applications
              for vulnerabilities. If there are weaknesses in your defences that
              need to be fixed, Nox Sentinel will find them. Production in
              progress...
            </p>
            <p>
              <a href="http://nox-sentinel.com/">www.nox-sentinel.com</a>
            </p>
          </div>
        </div>

        <div className="product-item">
          <img
            src={RedK}
            alt="Red Kangaroo Operating System"
            className="product-image"
          />
          <div className="product-description">
            <h2 style={{ fontFamily: "'Days One', sans-serif" }}>
              Red Kangaroo Operating System
            </h2>
            <p>
              A new hybrid-kernel operating system built entirely in Rust. Red
              Kangaroo OS and RedKangaroo Boot Loader are designed for
              performance, security, and reliability. This is an ongoing long
              term project, still in process. You can find out more at:
            </p>
            <p>
              <a href="http://redkangaroo-os.com">www.redkangaroo-os.com</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Products;
