import React from "react";
import "./Services.css";
import mobileAppsImg from "./Img/mobile.webp"; // Replace with actual image paths
import webAppsImg from "./Img/webone.webp";
import businessAppsImg from "./Img/business.webp";
import aiAppsImg from "./Img/ai.webp";
import gameDevImg from "./Img/game.webp";

function Services() {
  return (
    <div className="services-container">
      <h1 className="glowing-heading">Our Services</h1>
      <div className="services-list">
        <div className="service-item">
          <img
            src={mobileAppsImg}
            alt="Mobile Applications"
            className="service-image"
          />
          <div className="service-description">
            <h2>Mobile Applications</h2>
            <p>
              We specialize in developing mobile applications for both Android
              and iOS platforms. Our apps are designed to deliver a seamless
              user experience, leveraging the latest technologies to bring your
              ideas to life on mobile devices.
            </p>
          </div>
        </div>

        <div className="service-item">
          <img
            src={webAppsImg}
            alt="Web Applications"
            className="service-image"
          />
          <div className="service-description">
            <h2>Web Applications</h2>
            <p>
              Our team creates robust, scalable, and user-friendly web
              applications tailored to your business needs. We use current
              industry best practice for design and security in every project.
              Whether it's an e-commerce platform, a content management system,
              or a custom web solution, we've got you covered.
            </p>
          </div>
        </div>

        <div className="service-item">
          <img
            src={businessAppsImg}
            alt="Business Applications"
            className="service-image"
          />
          <div className="service-description">
            <h2>Business Applications</h2>
            <p>
              We develop custom business applications that streamline
              operations, enhance productivity, and drive growth. From ERP
              systems to CRM solutions, our applications are designed to meet
              the unique demands of your business.
            </p>
          </div>
        </div>

        <div className="service-item">
          <img
            src={aiAppsImg}
            alt="Artificial Intelligence"
            className="service-image"
          />
          <div className="service-description">
            <h2>Artificial Intelligence and Machine Learning</h2>
            <p>
              If you need help with sub-word transcoder transformer models or
              ultra high performance data engineering and machine learning you
              can rest assured that we have the expertise to deliver.
            </p>
          </div>
        </div>

        <div className="service-item">
          <img
            src={gameDevImg}
            alt="Game Development"
            className="service-image"
          />
          <div className="service-description">
            <h2>Game Development</h2>
            <p>
              Enter the world of immersive experiences with our game development
              services. We create engaging 2D, 3D, AR, and VR games that
              captivate players and bring your creative visions to life.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
