import React from "react";
import "./Products.css";
import australianDeadlyCreaturesImg from "./Img/Shark.png";
import littleBonsaiImg from "./Img/JapaneseMapleBonsai.jpg";

function iOS() {
  return (
    <div className="products-container">
      <h1 className="glowing-heading">Apps for iPhone and iPad</h1>
      <div className="products-list">
        <div className="product-item">
          <img
            src={australianDeadlyCreaturesImg}
            alt="Australian Deadly Creatures"
            className="product-image"
          />
          <div className="product-description">
            <h2 style={{ fontFamily: "'Days One', sans-serif" }}>
              Australian Deadly Creatures
            </h2>
            <p>
              Explore Australia’s most dangerous creatures through stunning
              watercolor illustrations. Available on the iOS Store, this app is
              a must-have for nature enthusiasts and thrill-seekers alike.
            </p>
          </div>
        </div>

        <div className="product-item">
          <img
            src={littleBonsaiImg}
            alt="Little Bonsai"
            className="product-image"
          />
          <div className="product-description">
            <h2 style={{ fontFamily: "'Days One', sans-serif" }}>
              Little Bonsai
            </h2>
            <p>
              Leern the ancient art of bonsai with this calming and immersive
              experience. Little Bonsai is now available on the iOS Store and is
              perfect for anyone looking to bring a little serenity into their
              life.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default iOS;
