import React from "react";
import "./Values.css";

function Values() {
  return (
    <div className="home-container">
      <header className="home-header">
        <h1 className="glowing-heading">Our Core Values</h1>
        <p className="home-intro">
          At Nox Ignis, our values shape the way we do business and guide our
          actions in all that we do.
        </p>
      </header>

      <section className="home-section card">
        <h2 style={{ fontFamily: "'Days One', sans-serif" }}>Integrity</h2>
        <p>
          We uphold the highest standards of integrity in all of our actions.
          &nbsp;
          <u>Honesty and transparency</u> are the cornerstones of our work,
          ensuring that we build trust with our clients, partners, and within
          our team.
        </p>
      </section>

      <section className="home-section card">
        <h2 style={{ fontFamily: "'Days One', sans-serif" }}>Mutual Respect</h2>
        <p>
          Respect is at the heart of our collaborative environment. We value the
          &nbsp;
          <u>diverse perspectives and contributions of each team member</u>,
          fostering a culture where <u>everyone is heard and valued</u>. We
          treat others as we wish to be treated, creating a supportive and
          inclusive workplace.
        </p>
      </section>

      <section className="home-section card">
        <h2 style={{ fontFamily: "'Days One', sans-serif" }}>
          Continuous Improvement
        </h2>
        <p>
          We are <u>committed to excellence</u> and the pursuit of continuous
          improvement. By embracing change and innovation, we constantly refine
          our processes, products, and services to deliver the best possible
          outcomes for our clients and ourselves.
        </p>
      </section>

      <section className="home-section card">
        <h2 style={{ fontFamily: "'Days One', sans-serif" }}>
          Staff Development
        </h2>
        <p>
          Our{" "}
          <u>
            people are our greatest asset, and their growth is essential to our
            success
          </u>
          . We invest in the development of our team, providing opportunities
          for learning, professional growth, and personal fulfillment. By
          nurturing talent, we build a stronger, more capable organization.
        </p>
      </section>

      <section className="home-section card">
        <h2 style={{ fontFamily: "'Days One', sans-serif" }}>Humility</h2>
        <p>
          We approach our <u>work with humility</u>, recognizing that there is
          always more to learn. We are grateful for our successes and view our
          challenges as opportunities for growth. By staying grounded, we
          maintain a focus on continuous improvement and collective success.
        </p>
      </section>

      <section className="home-section card">
        <h2 style={{ fontFamily: "'Days One', sans-serif" }}>
          Constructive Feedback
        </h2>
        <p>
          Feedback is a powerful tool for growth.{" "}
          <u>We actively seek and welcome constructive feedback</u>, using it to
          improve our work and ourselves. By embracing feedback with an open
          mind, we foster a culture of learning and adaptability.
        </p>
      </section>
    </div>
  );
}

export default Values;
