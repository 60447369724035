import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";
import "./App.css";
import Home from "./components/Home";
import Ios from "./components/iOS";
import Products from "./components/Products";
// import Prices from "./components/Prices";
import Services from "./components/Services";
import About from "./components/AboutUs";
import Footer from "./Footer";
import flame from "./components/Img/flame.png";
import Values from "./components/Values";

const isMobileDevice = () => {
  return /Mobi|Android/i.test(navigator.userAgent);
};

function App() {
  const isMobile = isMobileDevice();

  return (
    <Router>
      <div className="App">
        {isMobile ? (
          <nav className="navbar">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img src={flame} alt="Nox Ignis" className="navbar-logo" />
              <h1 className="navbar-heading-one">Nox</h1>
              <h1 className="navbar-heading-two">Ignis</h1>
            </div>
            <ul className="navbar-links" style={{ marginRight: "5%" }}>
              <li>
                <Link to="/ios">iOS Apps</Link>
              </li>
              <li>
                <Link to="/products">Products</Link>
              </li>
              <li>
                <Link to="/services">Services</Link>
              </li>
            </ul>
          </nav>
        ) : (
          <nav className="navbar">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img src={flame} alt="Nox Ignis" className="navbar-logo" />
              <h1 className="navbar-heading-one">Nox</h1>
              <h1 className="navbar-heading-two">Ignis</h1>
            </div>
            <ul className="navbar-links" style={{ marginRight: "5%" }}>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/ios">iOS Apps</Link>
              </li>
              <li>
                <Link to="/products">Products</Link>
              </li>
              <li>
                <Link to="/services">Services</Link>
              </li>
            </ul>
          </nav>
        )}
        <div className="content">
          <Routes>
            {isMobile && (
              <Route path="/" element={<Navigate to="/services" />} />
            )}
            <Route path="/" element={<Home />} />
            <Route path="/ios" element={<Ios />} />
            <Route path="/products" element={<Products />} />
            <Route path="/services" element={<Services />} />
            <Route path="/aboutus" element={<About />} />
            <Route path="/values" element={<Values />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
