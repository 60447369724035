import React from "react";
import "./Home.css";

function Home() {
  return (
    <div className="home-container">
      <header className="home-header">
        <h1 className="glowing-heading">Nox Ignis Software</h1>
        <p style={{ fontStyle: "italic" }}>
          "Bright Ideas, Brilliant Solutions"
        </p>
      </header>

      <section className="home-section card">
        <h2 style={{ fontFamily: "'Days One', sans-serif" }}>Our Passion</h2>
        <p>
          At Nox Ignis, our passion is the fuel that drives everything we do. We
          believe in the power of technology to transform the way we live and
          work. Our team is dedicated to turning innovative ideas into powerful
          software solutions that not only meet your needs but exceed your
          expectations. We strive to nurture the bright spark of creativity that
          illuminates the darkness.
        </p>
      </section>

      <section className="home-section card">
        <h2 style={{ fontFamily: "'Days One', sans-serif" }}>Our Strengths</h2>
        <ul>
          <p>
            We believe that the difference between good and great lies in the
            details. Our meticulous approach ensures that every aspect of your
            project is carefully considered, flawlessly executed and
            continuously improved.
          </p>
          <p>
            Quality is at the core of our ethos. From the initial concept to the
            final delivery, we maintain the highest standards to ensure that our
            software solutions are reliable, efficient, and user-friendly.
          </p>
          <div style={{ marginBottom: 40 }}>
            <p>
              In today's digital world, security is paramount. We are committed
              to exceeding industry cybersecurity standards to protect your data
              and give you peace of mind.
            </p>
          </div>
        </ul>
      </section>

      <section className="home-section card">
        <h2 style={{ fontFamily: "'Days One', sans-serif" }}>
          Powering Your Business Ideas
        </h2>
        <p>
          Whether you're a startup with a groundbreaking idea or an established
          company looking to innovate, Nox Ignis is here to help you bring your
          vision to life. Our expertise in software development, combined with
          our passion for technology, allows us to create tailored solutions
          that empower your business to grow and thrive.
        </p>
        <p>Let us be the spark that ignites your success.</p>
      </section>
      <section className="home-section card">
        <h2 style={{ fontFamily: "'Days One', sans-serif" }}>
          Technology Stack
        </h2>
        <p>
          All projects are now being transitioned to Next.js, Rust, Swift and
          Kotlin due to evolving cybersecurity threats.
        </p>
        <p>
          <i>
            "As a target of Australian political corruption, criminal
            vilificaiton, aggrivated criminal intimidation, criminal
            victimisation and hate crimes. I can sleep easy at night knowing
            that even if the federal police don't charge people, they will still
            burn in hell for their actions towards me." - Doc Brown
          </i>
        </p>
      </section>
    </div>
  );
}

export default Home;
